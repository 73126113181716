<template>
  <div>
    <b-link  @click="$router.push({name: 'my_work', params: { section: 'networks' } })" class="w-100 d-block pb-1 border-bottom">
      <feather-icon icon="ChevronLeftIcon"></feather-icon>
      {{$t('dashboard.backTo')}}
    </b-link>
    <div class="m-0 d-flex flex-wrap" v-if="loaded_profile">
      <view-connections-card 
        class="view-connections-card d-none d-xl-block p-1" 
        :networks_dad="profile.networks"
        :reports="reports"
        @update_parent="updateNetwork"
        v-if="Object.keys(profile).length > 0"
      />
      <div class="analytics-section p-0">
        <div class="card-analytics-profile-net p-3 border-left border-right">
          <div>
            <b-row class="d-flex align-items-center justify-content-between m-0">
              <div class="d-flex align-items-center flex-wrap flex-md-nowrap justify-content-center mb-1 w-custom">
                <div class="position-relative col-12 col-sm-auto mb-1 text-center text-sm-left">
                  <b-avatar variant="light" size="80px" class="mr-sm-2 ">
                    <b-img :src="getIconWhite(network_selected.network)" :class="getIconColor(network_selected.network)" fluid class="p-1"></b-img>
                  </b-avatar>
                </div>
                <div class="col overflow-hidden mb-1">
                  <h2 class="text-center text-sm-left">{{network_selected.name ? network_selected.name : network_selected.username}}</h2>
                  <div class="d-flex align-items-center" v-if="!(network_selected.network === 'linkedin' && network_selected.connected)">
                    <feather-icon icon="LinkIcon" class="mr-05"></feather-icon>
                    <a class="url-username-connection"
                      :href="getUrlNetwork(network_selected)"
                      target="_blank"
                    >
                      {{getUrlNetwork(network_selected)}}
                    </a>
                  </div>
                </div>
                <b-button class="d-block p-0 col-12 col-md-auto" variant="flat-secondary" v-if="!(network_selected.network === 'linkedin' && network_selected.connected)">
                  <b-link :href="getUrlNetwork(network_selected)" target="_blank" class="d-block p-1 url-username-connection">
                    {{ $t('creator.view_on') }} {{capitalize(network_selected.network)}}
                  </b-link>
                </b-button>
              </div>
            </b-row>

            <b-alert show class="p-1 alert-info-connection mt-1" v-if="!isAvaibleToAnalize">Por el momento no podemos crear reportes para esta red social. Prueba desbloqueando informes completos en cuentas de TikTok, Instagram, YouTube, Twitch y X (Twitter).</b-alert>

            <div v-else>
              <div>
                <b-tabs 
                  class="mt-1"
                  v-model="main_tabs"
                >
                  <b-tab title="Overview" :active="$route.params.section === 'Overview'">
                    <overview-network
                      class="border-top pt-1"
                      :network_selected="network_selected"
                      :metrics="analytics_overview"
                      @change_tab="changeTab"
                    />
                  </b-tab>
                  <b-tab title="Analytics" :active="$route.params.section === 'Analytics'">
                    <div class="w-100" v-if="!permission_metrics.already_viewed">
                      <p>{{ $t('detailProfile.accountFree') }}</p>
                      <div class="d-flex justify-content-around w-100 flex-wrap">
                        <b-button 
                          class="blue-button mb-1" 
                          variant="blue-button"
                          v-if="(is_plan_free || !permission_metrics.can_view_metrics) && !is_brand"
                          @click="open_modal_plans_group = true; update_modal = !update_modal"   
                        >{{ $t('memberships.updatePlan') }}</b-button>
                        <b-button class="blue-button mb-1" variant="blue-button" v-else @click="unlockReport()">
                          {{ $t('detailProfile.seeReport') }}
                          <b-img :src="require(`@/assets/images/icons/moneda.svg`)" class="icon-coin"></b-img> 1
                        </b-button>

                        <b-button class="mb-1 button-max-views" @click="buyReport" v-if="!is_brand">{{ $t('detailProfile.buyReport') }}</b-button>

                        <b-row class="mt-3">
                          <b-col class="d-flex col-12 mb-1 col-lg-6 col-xl-4">
                            <b-img :src="require(`@/assets/images/icons/bar-chart.svg`)" class="icon-default-chart"></b-img>
                            <div class="d-flex flex-column ml-1">
                              <h5 class="mb-1">{{ $t('detailProfile.demographyOf') }} {{ nameNetworks(this.network_selected.network) }}</h5>
                              <p class="avenir-medium">{{ $t('detailProfile.verifyDemography') }} @{{this.network_selected.username}}. {{ $t('detailProfile.fulleReportShows') }}</p>
                            </div>
                          </b-col>

                          <b-col class="d-flex col-12 mb-1 col-lg-6 col-xl-4">
                            <b-img :src="require(`@/assets/images/icons/dots-default-profile.svg`)" class="icon-default-chart"></b-img>
                            <div class="d-flex flex-column ml-1">
                              <h5 class="mb-1">{{ $t('detailProfile.analyzeQuality') }} @{{this.network_selected.username}}</h5>
                              <p class="avenir-medium">{{ $t('detailProfile.getAnalyzeQuality') }}</p>
                            </div>
                          </b-col>

                          <b-col class="d-flex col-12 mb-1 col-lg-6 col-xl-4">
                            <b-img :src="require(`@/assets/images/icons/gender-chart.svg`)" class="icon-default-chart"></b-img>
                            <div class="d-flex flex-column ml-1">
                              <h5 class="mb-1">{{ $t('preview.audience_age_gender') }}</h5>
                              <p class="avenir-medium">{{ $t('detailProfile.audienceGender') }} @{{this.network_selected.username}}. {{ $t('detailProfile.reportWillShow') }}</p>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <profile 
                      :metrics="metrics" 
                      :permission_metrics="permission_metrics"
                      @update_metrics="updateMetrics"
                      @buy_update_metrics="buyReport"
                      v-else-if="Object.keys(metrics).length > 0" 
                    ></profile>
                    <error-loading v-else></error-loading>
                  </b-tab>
                </b-tabs>
              </div>
              <div class="mt-2">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-membership-group
      :open_modal_plans_group="open_modal_plans_group" 
      :key="update_modal"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BAvatar,
  BButton,
  BTabs,
  BTab,
  // BPagination,
  BAlert,
  // BSpinner,
  BImg,
} from 'bootstrap-vue';
import service_others from '@/services/others';

import viewConnectionsCard from '@/views/components/proposals/viewConnectionsCard.vue';
import profile_services from '@/services/profile';
import { nameNetworks, formatDataAnalytics, capitalize } from '@/libs/utils/formats';
import { getUserData } from '@/libs/utils/user'
import { getIconWhite, getIconColor } from '@/libs/utils/icons'
import { getURL } from '@/libs/utils/urls';
import { getMotivationalPhrase } from '@/libs/utils/others';
const toastification_component = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'detailConnection',
  components: {
    BRow,
    BCol,
    BLink,
    BAvatar,
    BButton,
    BTabs,
    BTab,
    BImg,
    BAlert,
    viewConnectionsCard,
    overviewNetwork: () => import('@/views/pages/proposals/detail-profile-analytics/overviewNetwork.vue'),
    profile: () => import('@/views/pages/profile/Profile.vue'),
    ErrorLoading: () => import('@/views/pages/profile/ErrorLoading.vue'),
    ModalMembershipGroup: () => import('@/views/components/modal/ModalMembershipGroup.vue'),
  },
  data() {
    return {
      getMotivationalPhrase,
      getURL,
      capitalize,
      nameNetworks,
      getIconWhite,
      getIconColor,
      profile: {},
      network_selected: {},
      profile_analytics: {},
      analytics: null,
      media: [],
      loaded_profile: false,
      loaded_media: false,
      update_tab: false,
      no_data_for_profile: false,
      main_tabs: 0,
      current_page: 1,
      per_page: 12,
      can_view_profile: false,
      alert_error_loading_data: false,
      update_card_posts: false,
      loader_recent_post: false,
      interval_loading: null,
      reports: {},
      permission_metrics: {},
      metrics: {},
      analytics_overview: {},
      open_modal_plans_group: false,
      update_modal: false,
    }
  },
  created() {
    this.getProfile();
  },
  computed: {
    mediaFiltered () {
      const items = this.media;
      return items.slice((this.current_page - 1) * this.per_page, this.current_page * this.per_page)
    },
    isAvaibleToAnalize() {
      const networks_avaible = ['instagram', 'youtube', 'tiktok', 'twitch', 'twitter']
      return networks_avaible.includes(this.$route.params.network)
    },
    is_plan_free() {
      return getUserData().current_plan.name.toLowerCase() === 'free'
    },
    is_brand() {
      return getUserData() && getUserData().main_group.name !== 'creator'
    }
  },
  methods: {
    getMaxViewsBrand() {
      service_others.getMaxViews().then(response => {
        this.reports = {available_credits: response.max_views}
        this.permission_metrics.reports = this.reports;
      })
    },
    updateMetrics() {
      this.initLoading();
      profile_services.updateMetrics(this.network_selected.uuid).then((response) => {
        this.handleDataMetrics(response)
        this.permission_metrics.reports.available_credits -= 1;
      });
    },
    handleDataMetrics(response) {
      this.metrics = response.metrics;  
      this.analytics_overview = formatDataAnalytics(this.network_selected.network, response.metrics.main_child.detail)
      clearInterval(this.interval_loading)
      this.$vs.loading.close();
      this.loaded_profile = true;
    },
    unlockReport() {
      if (!this.is_brand) {
        this.getSpotlight(); 
        this.permission_metrics.already_viewed = true;
        this.permission_metrics.reports.available_credits -= 1;
      } else if (this.is_brand && this.reports.available_credits > 0) {
        this.getSpotlightBrand(); 
        this.permission_metrics.already_viewed = true;
        this.permission_metrics.reports.available_credits -= 1;
        
      } else {
        toastification_component().then((toast) => {
          this.$toast({
            component: toast.default,
            position: 'top-right',
            props: {
              title: 'Ups',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: this.$t('error.soldoutViews')
            }
          })
        })
      }
    },
    canUnlockOrPay() {
      profile_services.canUnlockOrPayProfile(this.network_selected.uuid).then((response) => {
        this.permission_metrics = response;
        this.getCreditsAvaible();
        this.loaded_profile = true;
        // temporal
        // this.getSpotlightBrand()
        if (this.permission_metrics.already_viewed) {
          this.getSpotlight()
        } else {
          clearInterval(this.interval_loading)
          this.$vs.loading.close();
        }
      })
    },
    getCreditsAvaible() {
      profile_services.getCreditsAvaible().then((response) => {
        this.reports = response;
        this.permission_metrics.reports = this.reports;
      })
    },
    sectionMembership() {
      this.$router.push({name: 'pages-account-setting', params: {section: 'membership'}})
    },
    changeTab(tab) {
      this.main_tabs = tab;
    },
    updateNetwork() {
      this.media = [];
      this.metrics = {};
      this.profile_analytics = {};
      this.network_selected = {};
      this.reports = {}
      this.analytics_overview = {}
      this.getProfile();
      if (this.isAvaibleToAnalize) {
        this.getCreditsAvaible()
      }
      this.loaded_profile = false;
      this.update_tab = !this.update_tab;
    },
    initLoading() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
    },
    getProfile() {
      this.initLoading()
      this.no_data_for_profile = false;
      this.alert_error_loading_data = false;
      service_others.getProfileByVanityName(this.$route.params.user).then((response) => {
        this.profile = response.user;
        this.network_selected = this.profile.networks.find((item) => item.username === this.$route.params.username && item.network === this.$route.params.network);
        if (!this.network_selected) {
          this.$router.push({name: 'dashboard-analytics'});
          clearInterval(this.interval_loading)
          this.$vs.loading.close();
        }
        if (this.isAvaibleToAnalize && !this.is_brand) this.canUnlockOrPay()
        else if (this.isAvaibleToAnalize && this.is_brand) {
          this.profilesVisited();
        } else {
          this.loaded_profile = true;
          clearInterval(this.interval_loading)
          this.$vs.loading.close();
        }
      });
    },
    getUrlNetwork(profile) {
      if (profile.profile_url) return profile.profile_url
      else if (profile.network === 'facebook' && profile.link) return profile.link
      return getURL(profile.network, profile.username, profile.is_channel)
    },
    getSpotlight() {
      profile_services.getSpotlightForPayment(this.network_selected.uuid).then((response) => {
        this.handleDataMetrics(response)
      });
    },
    getSpotlightBrand() {
      const params = {network: this.network_selected.network, username: this.network_selected.username}
      profile_services.getChild(params)
        .then(response => {
          this.handleDataMetrics({metrics: response})

          const formData = new FormData()
          formData.append('username', params.username)
          formData.append('network', params.network)
          this.markCheckedProfileBrand(formData);

          service_others.getMaxViews()
            .then(response => {
              this.$root.$emit('change_max_views', response.max_views)
            })
        })
        .catch(() => {})
    },
    profilesVisited() {
      const params = {network: this.network_selected.network, username: this.network_selected.username}
      
      this.getMaxViewsBrand();  
      service_others.getVisitedProfiles(params.network, params.username).then((response) => {
        if (response.results.length > 0) {
          this.permission_metrics.can_view_metrics, this.permission_metrics.already_viewed = true;
          this.getSpotlightBrand();
          
        } else {
          this.checkView(params)
          this.loaded_profile = true;
          clearInterval(this.interval_loading)
          this.$vs.loading.close();
        }
      })
    },
    checkView(params) {
      profile_services.visiteProfile(params)
        .then((response) => {
          if (response.allowed_visit) {
            this.permission_metrics.can_view_metrics = true;
            this.permission_metrics.already_viewed = false
          }
        })
    },
    markCheckedProfileBrand(formData) {
      profile_services.checkProfile(formData)
        .then(() => {})
    },
    buyReport() {
      profile_services.buyReport(this.network_selected.uuid).then((response) => {
        window.location.href = response.url;
      });
    },
    getMedia(network) {
      if (network === 'instagram' || network === 'tiktok') {
        const ws = this.network_selected.network === 'instagram' ? 'getMediaInstagram' : 'getMetricsVideosTiktok';
        profile_services[ws](this.network_selected.network === 'instagram' ? this.network_selected.username : this.analytics.report.basic.id).then((response) => {
          clearInterval(this.interval_loading)
          this.$vs.loading.close();
          this.loaded_media = true;
          const contents = Object.values(response.results);
          const contents_format = contents.map((content) => (
            { 
              url_external: network === 'instagram' ? `https://www.instagram.com/p/${content.basic.code}/` : `https://www.tiktok.com/@$${this.network_selected.username}/video/${content.basic.id}`,
              preview_url: network === 'instagram' ? content.basic.preview_url : content.basic.thumbnail,
              likes_count: network === 'instagram' ? content.metrics.likes_count : content.metrics.likes_count.value,
              comments_count: network === 'instagram' ? content.metrics.comments_count : content.metrics.comments_count.value,
              caption: network === 'instagram' ? content.basic.caption : content.basic.text,
            }
          ));
          this.media = contents_format;
        });
      } else {
        this.loaded_media = true;
        clearInterval(this.interval_loading)
        this.$vs.loading.close();
      }
    },
    getWebService() {
      if (this.network_selected.network === 'instagram') return ['getMediaInstagram', this.data.username.name.username];
      else if (this.network_selected.network === 'tiktok') return ['getMetricsVideosTiktok', this.data.actions.social_id];
    },
  },
  watch: {
    current_page() {
      this.loader_recent_post = true;
      this.update_card_posts = !this.update_card_posts;
      setTimeout(() => {
        this.loader_recent_post = false;
      }, 1000);
    }
  }
}
</script>
<style scoped lang="scss">
.w-custom {
  width: 100%;
  max-width: 100%;
}
.align-sidebar-responsive {
  width: 25px;
  height: 25px;
  cursor: pointer;
  width: 100%;
}
.view-connections-card {
  width: 420px !important;
  flex-shrink: 0;
}
.analytics-section {
  flex-grow: 1;
  // NO IDEA WHY THIS WIDTH BUT IT WORKS
  width: 300px;
}
.alert-info-connection {
  color: #3b8DA8 !important;
  background: #E6f2f6 !important;
}
.icon-default-chart {
  width: 50px;
  height: 50px;
}
.icon-coin {
  width: 20px;
  height: 20px;
}
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  right: 5px;
}
.loader-table-cards {
  top: 0px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-table-searcher {
  height: 6em;
  width: 6em;
  color: #acacac;
}
</style>
<style>
.container-loader-searcher {
  top: 0px;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
  left: 0;
}
.loader-table-cards {
  z-index: 10;
  display: flex;
  justify-content: center;
}
.spinner-table-searcher {
  margin-top: 17em;
  height: 6em;
  width: 6em;
  color: #acacac;
}
.border-icon-connection {
  border: 2px solid #eaeaea;
}
.mr-05 {
  margin-right: 0.5em;
}
.overflow-hidden {
  overflow: hidden;
}
.url-username-connection {
  color: #b1b1b1;
  display: block;
  width: 100%;
  overflow-x: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  box-sizing: border-box;
}
.card-analytics-profile-net {
  height: 100%;
  background-color: white;
}
.border-box-analytics {
  border: 1px solid #eaeaea;
  border-radius: 1em;
  padding: 0.5em;
  box-shadow: 0rem 0.375rem 0.75rem rgb(108 117 125 / 8%);
}
.icon-network-twitter-detail {
  width: 65%;
  height: 65%;
}
.icon-content-twitter-detail {
  display: flex;
  background: black;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
</style>
