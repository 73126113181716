import config from '@/services/config'
import axios from './axios_interceptors'
import {tokenExpired} from '@/auth/utils'
export default {
  spotlight(formData) {
    return axios.post(`${config.urlCore}/api/v2/spotlight/instagram/`,
      formData,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getChild(formData) {
    return axios.post(`${config.urlCore}/api/v2/spotlight/networks/`,
      formData,
      {headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }})
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getSpotlightForPayment(user_network_uuid) {
    return axios.get(`${config.urlCore}/api/v3/connections/spotlight/${user_network_uuid}/`,
      {headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }})
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  updateMetrics(user_network_uuid) {
    return axios.get(`${config.urlCore}/api/v3/connections/report-update/${user_network_uuid}/`,
      {headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }})
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  buyReport(user_network_uuid) {
    return axios.get(`${config.urlCore}/api/v3/connections/checkout/metrics/${user_network_uuid}/`,
      {headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }})
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getCreditsAvaible() {
    return axios.get(`${config.urlCore}/api/v3/connections/summary-credits/`,
      {headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }})
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  canUnlockOrPayProfile(user_network_uuid) {
    return axios.get(`${config.urlCore}/api/v3/connections/validate/view-profile/${user_network_uuid}/`,
      {headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }})
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getSimilarAccounts(network, alias) {
    return axios.get(`${config.urlCore}/api/v1/finder/similar/accounts/${network}/${alias}/`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getProfileImageForNetworks(data) {
    return axios.post(`${config.urlCore}/api/v1/finder/profile/image/`,data)
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getSimilarAccountsNoSession(network, alias) {
    return axios.get(`${config.urlCore}/api/v3/profile/preview/suggestions/${network}/${alias}/`)
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getPreviewProfile(network, alias) {
    return axios.get(`${config.urlCore}/api/v3/profile/preview/${network}/${alias}/`)
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getChildren(network, alias) {
    return axios.get(`${config.urlCore}/api/v1/finder/children/${network}/${alias}/`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  checkProfile(formData) {
    return axios.post(`${config.urlCore}/api/v2/searchs/`,
      formData,
      {headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }})
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  visiteProfile(formData) {
    return axios.post(`${config.urlCore}/api/v2/searchs/check/`,
      formData,
      {headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }})
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getCover() {
    return axios.get(`${config.urlCore}/api/v1/finder/cover/image/`)
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getMetricsVideosTiktok(channel_id) {
    return axios.get(`${config.urlCore}/api/v1/finder/get/media/tiktok/${channel_id}`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getMediaInstagram(username, size_page, sort, days, force_download = '1') {
    let url = `${config.urlCore}/api/v1/finder/get/media/instagram/${username}/`
    const queryParams = [];
    if (days) {
      queryParams.push(`days=${days}`);
    }
    if (size_page) {
      queryParams.push(`size_page=${size_page}`);
    }
    if (sort) {
      queryParams.push(`sort=${sort}`);
    }

    queryParams.push(`download_content=${force_download}`);
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }
  
    return axios.get(url,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getMentions(username) {
    return axios.get(`${config.urlCore}/api/v1/finder/get/brand/mentions/${username}`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  updatePaymentData(data) {
    return axios.post(`${config.urlCore}/api/v1/accounts/update-payment/`,
      data,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }
    ).then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  }
}
