<template>
  <div class="w-100">
    <b-row class="d-flex justify-content-between align-items-center pr-1">
      <b-link class="pl-1 link-conection">{{$t('creator.networks')}}</b-link>
      <b-link @click="show_sider_network = true"  v-if="!is_vanity_user">{{ $t('edit') }}</b-link>
      <b-button class="button-max-views" v-if="Object.keys(reports).length > 0">
        {{ $t('maxviews')}} {{ reports.available_credits }}
      </b-button>
    </b-row>
    <hr/>
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scroll-area max-height-networks"
    >
    <no-results v-if="networks.length === 0" :title="$t('creator.noNetTitle')" :subtitle="$t('creator.noNetSubtitle')" :show_button="false"/>
    <div v-for="(network, index) in networks" :key="index" class="network-item-list-manage" :style="`border-left: 15px solid ${getColorV2(network.network, false)}`" @click="routerPush(network)">
      <div :style="`background: ${getColorV2(network.network,false)}`" class="background-color-net d-none d-sm-flex">
        <b-img :src="getIconWhite(network.network)" fluid class="icon-profile-connection"/>
      </div>
      
      <div :style="`background: ${getColor(network.network, true)}`" :class="`${network.vanity_name === '' ? 'hover-card-network-connected-detail' : ''} align-items-center justify-content-center text-center d-none`">
          <p class="m-1">{{ $t('creator.textHoverCard') }}</p>
        </div>
      <div class="p-1 d-flex justify-content-between align-items-center w-100">
            <div class="div-user-size">
              <strong class="username-hover-network mt-1" @click="routerPush(network)" :style="`color: ${getColor(network.network, true)}`">
                {{network.name ? network.name : network.username}}
              </strong>
              <b-row v-if="network.er !== null">
              <b-col>
                <p v-if="network.followers === 0 && !is_vanity_user" ><b-badge class="danger-pill-badge mt-03">{{network.followers}} {{$t('campaigns.followers')}}</b-badge></p>
                <p v-else class="number-eng-conection">{{(network.followers !== 0) ? getFormat(network.followers) : '--'}}  {{$t('campaigns.followers')}}</p>
              </b-col>
            </b-row>
            </div>
          </div>
      

    </div>
    </vue-perfect-scrollbar>
    <sider-network 
      class="open-sidebar-search"
      @close_siderbar="closeSiderNetwork"
      :show_sider_network="show_sider_network"
      :networks="networks"
      :is_adding="false"
      :show_footer="true"
      @updated_network="addedNetwork"
      @updated_network_edit="updateNetwork"
      @delete_network="deleteNetwork"
      v-if="show_sider_network"
    />
  </div>

</template>

<script>
import {
  BBadge,
  BLink,
  BRow,
  BCol,
  BImg,
  BButton,
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { getAssetUrl } from '@/libs/utils/urls';
import siderNetwork from "@/views/pages/proposals/stepper/siderNetwork.vue";
import { getIconWhite, getColor, getIconColor, getColorV2 } from '@/libs/utils/icons'
import { getFormat } from '@/libs/utils/formats';
import { getUserData } from '@/libs/utils/user';

export default {
  name: 'viewConnectionsCard',
  components: {
    BBadge,
    BLink,
    BRow,
    BCol,
    BButton,
    siderNetwork,
    BImg,
    VuePerfectScrollbar,
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue'),
  },
  data() {
    return {
      getColorV2,
      getUserData,
      getFormat,
      getAssetUrl,
      getColor,
      getIconWhite,
      getIconColor,
      show_sider_network: false,
      networks: this.networks_dad,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
    }
  },
  props: {
    networks_dad: {
      type: Array,
    },
    reports: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    is_vanity_user() {
      return this.$route.params.user;
    },
  },
  methods: {
    deleteNetwork(network_uuid) {
      this.networks = this.networks.filter((net) => net.uuid !== network_uuid)
      this.$emit('delete_network', network_uuid)
    },
    addedNetwork(network) {
      this.networks.unshift(network)
    },
    updateNetwork(network) {
      this.networks = this.networks.map((i) => {
        if (i.uuid === network.uuid) {
          return { ...network };
        }
        return i;
      });
      this.$emit("updated_network_edit", network);
    },
    closeSiderNetwork() {
      this.show_sider_network = false
    },
    routerPush(network) {
      if (getUserData()) {
        if (network.vanity_name) {
          this.$router.push({ name: 'detail_connection', params: { network: network.network, username: network.username, user: network.vanity_name, section: 'Overview' } }).catch();
          this.$emit('update_parent');
        } else {
          this.$router.push({ name: 'setting-public-profile', params: {section: 'connections'}})
        }
      } else {
        this.$emit('open_modal_login')
      }
    },
    redirectAnalytics(network) {
      if (getUserData()) {
        if (network.vanity_name) {
          this.$router.push({ name: 'detail_connection', params: { network: network.network, username: network.username, user: network.vanity_name, section: 'Analytics' } }).catch();
          this.$emit('update_parent');
        } else {
          this.$router.push({ name: 'setting-public-profile', params: {section: 'connections'}})
        }
      } else {
        this.$emit('open_modal_login')
      }
    },
  }
}
</script>
<style scoped lang="scss">
.network-item-list-manage {
  border: 1px solid #ccc;
  margin-bottom: 1em;
  max-height: 85px;
  border-radius: 1em;
  overflow: hidden;
  display: flex;

  @media(max-width: 575px) {
    height: auto;
  }
  @media(min-width: 576px) {
    border: 1px solid #ccc !important;
  }
  
  .background-color-net {
    width: 50px;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #cccccc60;
  }
  .icon-profile-connection {
    width: 30px !important;
    height: 30px !important;
    margin: 0em 2em 
  }
}
</style>
<style lang="scss">
.mt-03 {
  margin-top: 3px;
}
.danger-pill-badge {
  color: rgb(116, 17, 17) !important;
  background-color: rgba(255, 0, 0, 0.102) !important;
  font-size: 11px;
}
.number-eng-conection {
  font-size: 1rem;
  color: #6c757d !important;
  font-weight: 500 !important;
}
.link-conection {
  color:black !important;
  font-size: 1.4rem;
  font-weight: bold;
}
.username-hover-network {
  width: 100px;  
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;

}
.username-hover-network:hover {
  text-decoration: underline;
  cursor: pointer;
}
.max-height-networks {
  height: 900px;
}
.network-item-list-manage {
  position: relative;
  overflow: hidden;
}

.network-item-list-manage:hover .hover-card-network-connected-detail {
  display: flex !important;
  z-index: 10;
  position: absolute;
  top: 0;
  cursor: pointer;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  opacity: 0.94;
  animation: active_card 400ms;
}

@keyframes active_card {
  0% {
    transform: translateY(500px);
  }
  100% {
    transform: translateY(0px);
  }
}

.hover-card-network-connected-detail {
  display: none;
}

</style>