import axios from './axios_interceptors'
import config from '@/services/config'
import {tokenExpired} from '@/auth/utils'

export default {
  get_url_login_twitter() {
    return axios.get(`${config.urlCore}/api/v3/social/login/twitter/`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      });
  },
  redeem_token_twitter(oauth_key, oauth_token, oauth_verifier, username) {
    return axios.get(`${config.urlCore}/api/v3/social/redeem/twitter/${oauth_key}/${oauth_token}/${oauth_verifier}/?username=${username}`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response.data.response
      });
  },
  save_token_facebook(data) {
    return axios.post(`${config.urlCore}/api/v3/social/save/token/facebook/`, data, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response.data.response
      })
  },
  save_token_instagram(data) {
    return axios.post(`${config.urlCore}/api/v3/social/save/token/instagram/`, data, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response.data.response
      })
  },
  validate_token(user_network_uuid) {
    return axios.get(`${config.urlCore}/api/v3/social/validate/token/${user_network_uuid}/`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response.data.response
      });
  },
  redeem_code(network, data) {
    return axios.post(`${config.urlCore}/api/v3/social/redeem/code/${network}/`, data, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response.data.response
      })
  },
  get_info_youtube(user_network_uuid) {
    return axios.get(`${config.urlCore}/api/v3/social/get/info/youtube/${user_network_uuid}/`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response.data.response
      });
  },
  get_media_instagram(user_network_uuid) {
    return axios.get(`${config.urlCore}/api/v3/social/get/media/instagram/${user_network_uuid}/`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response.data.response
      });
  },
  get_stories_instagram(username) {
    return axios.get(`${config.urlPhp}/api/v2/historias/${username}`)
      .then(response => response.data.data)
      .catch(error => {
        return error
      });
  },
  enable_download_stories() {
    return axios.get(`${config.urlCore}/api/v3/social/download-stories/`)
      .then(response => response.data.response)
      .catch(error => {
        return error
      });
  }
}